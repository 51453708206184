<template>
    <div>
        <delete-dialog :person="person" :dialog.sync="deleteDialog" @filter="filterPersonen" :accept.sync="acceptDelete"></delete-dialog>
        <v-data-table :headers="headers"
                      :items="filteredPersonen"
                      class="elevation-1"
                      multi-sort
                      :expanded.sync="expanded"
                      item-key="id"
                      color="accent">
            <template v-slot:item="props">
                <tr @click="callPerson(props.item)" :class="{'black text-white': props.isExpanded}" style="z-index: 999">
                    <td class="bold">{{ props.item.anrede }}</td>
                    <td class="bold">{{ props.item.titel }}</td>
                    <td class="bold">{{ props.item.vorname }}</td>
                    <td class="bold">{{ props.item.nachname }}</td>
                    <td class="bold">{{ props.item.email }}</td>
                    <td class="bold">{{ props.item.telefon }}</td>
                    <td class="bold">{{ props.item.firma }}</td>
                    <td class="bold">{{ props.item.ort }}</td>
                    <td class="bold">{{ props.item.kontaktVon }}</td>
                </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }" >
              <transition name="slide-y-transition">
              <td :colspan="headers.length"  v-if="person" >
                <v-card flat class="grey lighten-3 pa-2"  v-if="person">
<!--                   <span class="subHeader">Person<v-btn v-if="!dirtyPerson" disabled outlined small fab color="grey">-->
<!--                              <v-icon>mdi-cached</v-icon></v-btn><v-btn @click="restorePerson()" v-if="dirtyPerson" outlined small fab color="accent"><v-icon  color="accent">mdi-cached</v-icon></v-btn></span>-->
                    <div class="text-right">
                      <v-btn @click="updatePerson(item, headers)" class="accent" dark right>Speichern</v-btn>
                      <v-btn @click="deleteDialog = true" color="black" class="ml-2" outlined right>Person Löschen</v-btn>
                    </div>
                  <v-row>
                    <v-col cols="2">
                      <v-select :items="anrede"
                                color="black"
                                v-model="person.anrede"
                                label="Anrede"></v-select>
                    </v-col>
                    <v-col  cols="2">
                      <v-text-field label="Titel"
                                    v-model="person.titel"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Vorname"
                                    v-model="person.vorname"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col  cols="2">
                      <v-text-field label="Nachname"
                                    v-model="person.nachname"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Name Zusatz"
                                    v-model="person.namenZusatz"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Position"
                                    v-model="person.position"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="E-mail"
                                    v-model="person.email"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Telefon"
                                    v-model="person.telefon"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Mobile"
                                    v-model="person.mobil"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-menu ref="menu1"
                              :close-on-content-click="false"
                              v-model="menu1"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on"
                                      v-bind="attrs"
                                      v-model="computedDateFormatted"
                                      label="Geburtstag"
                                      color="black"
                                      prepend-icon="mdi-calendar"
                                      readonly></v-text-field>
                        </template>
                        <v-date-picker ref="picker"
                                       v-model="person.geburtstag"
                                       :max="new Date().toISOString().substr(0, 10)"
                                       min="1900-01-01" dark locale="de-de"
                                       @change="save(person.geburtstag)"></v-date-picker>

                      </v-menu>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Straße"
                                    v-model="person.straße"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="PLZ"
                                    v-model="person.plz"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Ort"
                                    v-model="person.ort"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete v-model="person.land.id"
                                      :items="laender"
                                      item-text="name"
                                      item-value="id"
                                      label="Land (privat)"
                                      color="black"
                                      persistent-hint>
                        <v-slide-x-reverse-transition slot="append-outer"
                                                      mode="out-in">
                        </v-slide-x-reverse-transition>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Kontakt Von"
                                    v-model="person.kontaktVon"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Bemerkung"
                                    v-model="person.bemerkung"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field label="Quelle"
                                    v-model="person.quelle"
                                    @change="changeDirtyPerson()"
                                    color="black"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-select :items="aktionsteilnahmen"
                                v-model="aktionsteilnahmen"
                                chips
                                label="Aktionen" multiple color="black" item-text="name" disabled></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select :items="praesente"
                                v-model="person.praesentZuweisungen"
                                chips
                                disabled
                                item-value="id"
                                label="Präsente" multiple color="black" item-text="name"></v-select>
                    </v-col>
                  </v-row>
                </v-card>
                <v-spacer></v-spacer>
                <v-card flat class="grey lighten-3 pa-2">
                  <v-row v-if="person.firma != null">
                    <v-col md="2">
                      <v-autocomplete v-model="person.firma.id"
                                      :items="firmen"
                                      item-text="name"
                                      item-value="id"
                                      label="Firma"
                                      @change="loadNewFirma"
                                      persistent-hint
                                      color="black">
                        <v-slide-x-reverse-transition slot="append-outer"
                                                      mode="out-in">
                        </v-slide-x-reverse-transition>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="2">
                      <v-text-field label="Straße"
                                    v-model="person.firma.straße"
                                    disabled
                                    filled></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field label="Plz"
                                    v-model="person.firma.plz"
                                    disabled
                                    filled></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field label="Ort"
                                    v-model="person.firma.ort"
                                    disabled
                                    filled></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field filled
                                    disabled
                                    v-model="person.firma.land"
                                    label="Land"></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field label="Telefon"
                                    @change="loadNewFirma()"
                                    v-model="person.firma.telefon"
                                    disabled
                                    filled></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </td>
              </transition>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                dateMenu: false,
                person: false,
                firma: null,
                personCopy: null,
                firmaCopy: null,
                newFirma: null,
                expanded: [],
                aktionsteilnahmen: null,
                personPraesente: [],
                menu1: false,

                acceptDelete: false,
                deleteDialog: false,

                dirtyPerson: false,
                dirtyFirma: false,
                dirtyFirmaSelect: false,
                dirtyFilter: false,

                anrede: ['Herr', 'Frau', 'K/A'],

                headers: [
                    { text: 'Anrede', value: 'anrede', sortable: true },
                    { text: 'Titel', value: 'titel', sortable: true },
                    { text: 'Vorname', value: 'vorname', sortable: true },
                    { text: 'Nachname', value: 'nachname', sortable: true },
                    { text: 'Email', value: 'email', sortable: true },
                    { text: 'Telefon', value: 'telefon', sortable: true },
                    { text: 'Firma', value: 'firma', sortable: true },
                    { text: 'Ort', value: 'ort', sortable: true },
                    { text: 'Kontakt Von', value: 'kontaktVon', sortable: true }],

            }
        },
        watch: {
            dateMenu(val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        beforeMount() {
        },
        mounted() {
        },
        props: ['firmen', 'laender', 'sortPersonen'],
        computed: {
            ...mapState(['aktionen', 'filteredPersonen', 'praesente', 'loading']),

            computedDateFormatted() {
                return this.formatDate(this.person.geburtstag)
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null


                 var date = new Date(date);

                var day = date.getDate().toString();
                day = day.length == 1 ? "0" + day : day;

                var month = (date.getMonth() + 1).toString();
                month = month.length == 1 ? "0" + month : month;

                var year = date.getFullYear().toString();
                return day + '.' + month + '.' + year;
            },
          clearExpanded() {
            this.expanded = [];
          },
          expand(value){
            const item = this.expanded.find(i => i.id === value.id);
            const index = this.expanded.indexOf(item);

            if(index > -1){
              this.expanded.splice(index, 1);
            }
            else{
              this.expanded = [];
              this.expanded.push(value);
            }
          },
            save(date) {
                this.$refs.menu1.save(date)
            },
            restorePerson: function (modus) {
                for (var prop in this.person) {
                    if (prop == 'firma' || prop == 'land') {
                        continue;
                    }
                    this.person[prop] = this.personCopy[prop];
                }
                this.dirtyPerson = false;

            },
            restoreFirma: function () {
                if (this.firmaCopy == null) {
                    this.firma = null;
                    this.newFirma = null;

                }
                for (var prop in this.firma) {
                    this.firma[prop] = this.firmaCopy[prop];
                }
                this.dirtyFirma = false;
            },
            changeDirtyPerson: function () {
                if (!this.dirtyPerson) {
                    this.dirtyPerson = true
                }
            },
            changeDirtyFirma: function () {
                if (!this.dirtyFirma) {
                    this.dirtyFirma = true
                }
            },
            callPerson: function (person) {
                this.$http.get('/api/Stammdaten/GetPerson/' + person.id).then(response => {
                    this.person = response.data;
                    if (this.person.land == null) {
                        this.person.land = { id: 0 };
                    }

                    if (this.person.geburtstag != null) {
                        var bday = this.person.geburtstag;
                        this.person.geburtstag = bday.substr(0, 10);
                    }
                }).catch((error) => console.log(error))

                this.setAktionen(person.id);
                this.expand(person);
            },
            setAktionen: function (id) {
                this.$http.get('/api/Stammdaten/AktionsNamen/' + id).then(response => {
                    this.aktionsteilnahmen = response.data;
                }).catch((error) => console.log(error));
            },
            setNewFirma: function () {
                var id = this.newFirma;
                this.firmaCopy = null;
                var newFirma = this.firmen.filter(function (element) { return element.id == id })[0];
                this.firma = newFirma;
                this.dirtyFirma = true;
            },
            filterPersonen(){
              this.$emit('filter');
            },
            loadNewFirma: function () {
                this.$http.get('/api/Stammdaten/GetFirma/' + this.person.firma.id).then(response => {
                    this.person.firma = response.data;
                }).catch((error) => console.log(error));
            },
            updatePerson: function (props) {
                this.$http.post('/api/Stammdaten/updatePerson', this.person).then(response => {
                    this.clearExpanded();
                    this.$store.dispatch('updatePerson', this.person);
                }).catch((error) => console.log(error));
            },

        }
    }
</script>

<style scoped>

    .bold {
        font-weight: 700;
    }
</style>
